import makeStyles from "@mui/styles/makeStyles";
import theme from "../../../theme";

const useStyles = makeStyles({
  accordionGroup: {
    width: '100%',
    border: '0px !important',
    borderBottomLeftRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    borderTopLeftRadius: '0px !important',
    borderTopRightRadius: '0px !important',
    "&::before": {
      height: '0px !important'
    }
  },
  accordionGroupExpanded: {
    margin: '0px !important',
    border: '0px !important',
  },
  accordianSummary: {
    minHeight: '36px !important',
    border: '0px !important',
  },
  accordianSummaryContent: {
    margin: '0px !important',
  },
  accordianSummaryExpandIcon: {
    padding: '0px 12px !important'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px !important'
  }
});

const styles = {
  drawerContainer: { 
    width: {
      md: '240px'
    },
    flexShrink: {
      md: 0
    }
  },
  drawer: {
    display: { 
      xs: 'none',
      md: 'block'
    },
    '& .MuiDrawer-paper': { 
      boxSizing: 'border-box',
      width: '240px',
      top: '60px',
      paddingBottom: '60px'
    },
  },
  drawerMobile: {
    display: { 
      xs: 'block',
      md: 'none'
    },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: '240px',
      paddingBottom: '60px'
    },
  },
  selectedTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '10px 0px 0px 0px',
    paddingLeft: '10px',
    cursor: 'pointer'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '10px 0px 0px 0px',
    paddingLeft: '10px',
    cursor: 'pointer'
  },
  endpointSection: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '8px 0px 8px 16px'
  },
  endpointNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '8px 0px 8px 48px',
    alignItems: 'center',
  },
  endpointName: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    fontWeight: 500,
    cursor: 'pointer'
  },
  selectedEndpointName: {
    color: theme.palette.primary.main,
    fontSize: '13px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  deprecatedEndpointName: {
    color: '#B0BEC5',
    fontSize: '13px',
    fontWeight: 500,
    cursor: 'pointer',
  },
  dotIcon: {
    color: theme.palette.text.primary,
    fontSize: '10px'
  },
  accordianContainer: {
  },
};

export default { styles, useStyles };
